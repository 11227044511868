<script setup lang="ts">
const user = useSupabaseUser()

const letsGo = () => {
  if (user.value) {
    navigateTo('/games')
  } else {
    navigateTo('/login')
  }
}
</script>

<template>
  <div class="flex min-h-screen justify-center">
    <div
      class="grid grid-cols-1 md:grid-cols-2 justify-between hero bg-base-200 my-8"
    >
      <div class="hero-img flex justify-center items-center mx-8">
        <img src="/img/hero.webp" class="w-full md:w-96 md:h-96 rounded-3xl" />
      </div>
      <div
        class="hero-content flex items-start md:items-center justify-center text-center"
      >
        <div class="max-w-md">
          <h1 class="text-5xl font-bold">Light of Learning!</h1>
          <p class="py-6 mx-8">
            Future bright minds, ready to glow? Our games will light your way to
            becoming a school superstar!
          </p>
          <button
            class="btn btn-primary bg-primary text-white p-2 rounded-xl w-1/2"
            @click="letsGo"
          >
            Let's go!
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
